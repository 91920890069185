import { createStyles } from '@material-ui/core/styles';

const styles = () =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      position: 'fixed',
    },
    iosIphoneHack: {
      width: 1,
      height: 1,
    },
  });

export default styles;
