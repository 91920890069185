import React from 'react';
import Tour from 'reactour';

import {
  IconButton,
  makeStyles,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  TouchApp as TouchAppIcon,
} from '@material-ui/icons';

import styles from './TourLanding.styles';

import type { TourLandingProps } from './TourLanding.types';

const useStyles = makeStyles(styles);

const TourLanding: React.FC<TourLandingProps> = ({
  onPointMarkerStep,
  onVirtualTourStep,
  ...other
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const renderContent = (content: React.ReactNode) => (
    <Typography variant="body2">{content}</Typography>
  );

  return (
    <Tour
      {...other}
      steps={[
        {
          selector: '#navButtonGroup',
          content: renderContent(
            'Change layout type by selecting on either Type 1 or Type 2.'
          ),
        },
        {
          selector: '.nav',
          content: renderContent(
            'Show features by selecting on different types of filters.'
          ),
        },
        {
          selector: '#canvasWrapper',
          content: (
            <>
              {renderContent(
                'Drag, swipe or use the mousewheel to rotate the layout view.'
              )}
              <Paper
                variant="outlined"
                className={classes.paperSwipeIconWrapper}
              >
                <TouchAppIcon
                  color="action"
                  fontSize="large"
                  className={classes.swipeIcon}
                />
              </Paper>
            </>
          ),
        },
        {
          selector: '.pointMarker',
          action: onPointMarkerStep,
          content: renderContent(
            'Mouse hover or press and hold to view the description of the feature.'
          ),
        },
        {
          selector: '.virtualTourIconButton',
          action: onVirtualTourStep,
          content: renderContent(
            'Click on any of the icons to enter the virtual tour.'
          ),
        },
        {
          selector: '#helpButton',
          content: renderContent(
            'Click on the Help button to open up this tour again.'
          ),
        },
      ]}
      className={classes.tourHelper}
      accentColor={theme.palette.primary.light}
      rounded={theme.shape.borderRadius}
      prevButton={
        <IconButton component="div" tabIndex={-1} size="small">
          <ChevronLeftIcon />
        </IconButton>
      }
      nextButton={
        <IconButton component="div" tabIndex={-1} size="small">
          <ChevronRightIcon />
        </IconButton>
      }
    />
  );
};

export default TourLanding;
