import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from '../';

const LandingPage = lazy(() => import('../../pages/Landing'));
const LandingType1VirtualTourPage = lazy(
  () => import('../../pages/LandingType1VirtualTour')
);
const LandingType2VirtualTourPage = lazy(
  () => import('../../pages/LandingType2VirtualTour')
);

const Root: React.FC = () => (
  <Switch>
    <Route
      exact
      path={ROUTES.LandingType1VirtualTour}
      component={LandingType1VirtualTourPage}
    />
    <Route
      exact
      path={ROUTES.LandingType2VirtualTour}
      component={LandingType2VirtualTourPage}
    />
    <Route path={ROUTES.Landing} component={LandingPage} />
    <Redirect to={ROUTES.Landing} />
  </Switch>
);

export default Root;
