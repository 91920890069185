import { Theme } from '@material-ui/core';
import { createStyles, fade } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: fade(theme.palette.primary.main, 0.9),
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.light, 0.9),
      },
    },
    focusVisible: {
      backgroundColor: `${fade(theme.palette.primary.light, 0.9)} !important`,
    },
    selected: {
      backgroundColor: `${fade(theme.palette.primary.light, 0.9)} !important`,
    },
  });

export default styles;
