import { createStyles } from '@material-ui/core/styles';

const styles = () =>
  createStyles({
    switchWrapper: {
      position: 'relative',
      '& > div': {
        position: 'absolute',
        // width: '100%',
        // height: '100%',
      },
    },
  });

export default styles;
