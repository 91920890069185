import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    backdropRoot: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
    },
  });

export default styles;
