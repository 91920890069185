import qs from 'query-string';
import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from '../';

// import { AnimatedSwitchFade } from '../../components'; // TODO: enhancement if possible

const LandingType1Page = lazy(() => import('../../pages/LandingType1'));
const LandingType2Page = lazy(() => import('../../pages/LandingType2'));

const Landing: React.FC = () => (
  <Switch>
    <Route exact path={ROUTES.LandingType1} component={LandingType1Page} />
    <Route exact path={ROUTES.LandingType2} component={LandingType2Page} />
    <Redirect
      to={{
        pathname: ROUTES.LandingType1,
        search: `?${qs.stringify({ filter: 'exterior' })}`,
      }}
    />
  </Switch>
);

export default Landing;
