import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    tourHelper: {
      [theme.breakpoints.only('xs')]: {
        maxWidth: '300px !important', // Mobile helper width fix.
      },
      '& div[data-tour-elem="controls"]': {
        justifyContent: 'center',
      },
    },
    paperSwipeIconWrapper: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2.5),
      display: 'flex',
      alignCenter: 'center',
      justifyContent: 'center',
    },
    swipeIcon: {
      transformOrigin: '30% 100%',
      animationName: '$swipeAnimation',
      animationTimingFunction: theme.transitions.easing.easeInOut,
      animationDuration: `${theme.transitions.duration.standard * 10}ms`,
      animationIterationCount: 'infinite',
    },
    '@keyframes swipeAnimation': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '15%': {
        transform: 'rotate(-15deg)',
      },
      '30%': {
        transform: 'rotate(15deg)',
      },
      '60%': {
        transform: 'rotate(-20deg)',
      },
      '70%': {
        transform: 'rotate(5deg)',
      },
      '80%': {
        transform: 'rotate(0deg)',
      },
    },
  });

export default styles;
