import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '100%',
      border: `2px solid ${theme.palette.common.white}`,
    },
    sizeDefault: {
      padding: theme.spacing(1),
    },
    sizeSmall: {
      padding: theme.spacing(0.65625),
    },
    colorOrange: {
      backgroundColor: theme.palette.secondary.main,
    },
    colorGreen: {
      backgroundColor: theme.palette.primary.main,
    },
  });

export default styles;
