import clsx from 'clsx';
import React, { forwardRef } from 'react';

import { makeStyles, Paper } from '@material-ui/core';

import styles from './PointMarker.styles';

import type { PointMarkerProps } from './PointMarker.types';

const useStyles = makeStyles(styles);

const PointMarker: React.ForwardRefRenderFunction<any, PointMarkerProps> = (
  { colorGreen, colorOrange, sizeSmall = false, ...other },
  ref
) => {
  const classes = useStyles();
  return (
    <Paper
      ref={ref}
      {...other}
      className={clsx(classes.root, other.className, {
        [classes.colorGreen]: colorGreen,
        [classes.colorOrange]: colorOrange,
        [classes.sizeDefault]: !sizeSmall,
        [classes.sizeSmall]: sizeSmall,
      })}
    />
  );
};

export default forwardRef(PointMarker);
