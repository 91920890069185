import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.black,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    gutters: {
      padding: theme.spacing(2.5, 2),
    },
  });

export default styles;
