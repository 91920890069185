export { default as LandingRoutes } from './Landing';
export * from './Landing';

export { default as RootRoutes } from './Root';
export * from './Root';

export const ROUTES = {
  Landing: '/',
  LandingType1: '/type-1/',
  LandingType1VirtualTour: '/type-1/virtual-tour/',
  LandingType2: '/type-2/',
  LandingType2VirtualTour: '/type-2/virtual-tour/',
};
