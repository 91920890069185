import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(2),
      backgroundColor: 'transparent',
      border: 0,
    },
  });

export default styles;
