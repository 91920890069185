import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
    fontFamily: 'nunito-sans',
  },
  palette: {
    primary: {
      main: '#184042',
      light: '#2e8a8f',
    },
    secondary: {
      main: '#d28736',
    },
    background: {
      default: 'white',
    },
  },
});
