import React from 'react';

import {
  Backdrop,
  BackdropProps,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';

import styles from './BackdropLoader.styles';

const useStyles = makeStyles(styles);

const BackdropLoader: React.FC<BackdropProps> = (props) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdropRoot} {...props}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default BackdropLoader;
