import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    buttonRoot: {
      position: 'relative',
      '&:not($disableImageSlider):hover $buttonImage': {
        top: 0,
      },
      '&:not($disableImageSlider):hover $buttonImageWrapper': {
        minHeight: '100vh', // Fix for image overflow on different viewport size.
      },
    },
    buttonFocusVisible: {
      '&:not($disableImageSlider) $buttonImage': {
        top: 0,
      },
      '&:not($disableImageSlider) $buttonImageWrapper': {
        minHeight: '100vh', // Fix for image overflow on different viewport size.
      },
    },
    buttonImageWrapper: {
      overflow: 'hidden',
      pointerEvents: 'none',
      position: 'absolute',
      top: '100%',
      width: '100%',
    },
    buttonImage: {
      left: 0,
      borderRadius: theme.spacing(0, 0, 1.1, 1.1),
      position: 'absolute',
      top: '-100%',
      transition: theme.transitions.create('top', {
        duration: theme.transitions.duration.complex,
      }),
      width: '100%',
    },
    disableImageSlider: {},
  });

export default styles;
