import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconVirtualTour360: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 367.72 212.51">
    <g>
      <g>
        <path d="M317.11,24.94a26.51,26.51,0,0,1,3.19-12.52,21.8,21.8,0,0,1,9-9.23,26.7,26.7,0,0,1,13-3.19q11.13,0,18.24,7.23t7.11,18.26q0,11.13-7.18,18.45a24.25,24.25,0,0,1-18.08,7.32,28.52,28.52,0,0,1-12.86-3,20.88,20.88,0,0,1-9.3-8.93A29.73,29.73,0,0,1,317.11,24.94Zm13.3.69q0,7.29,3.47,11.17a11.32,11.32,0,0,0,17.06,0q3.44-3.87,3.44-11.26,0-7.21-3.44-11.09a11.32,11.32,0,0,0-17.06,0Q330.42,18.34,330.41,25.63Z" />
        <path d="M313.13,85q-.64,16.06-4.3,27.67c24.34,6.43,35.28,13.23,40,17.27-5.26,4.52-18.2,12.86-49.07,19.51-17.84,3.84-39.83,8.55-46.53,9.7v28.63c10.25-1.72,47.22-9.61,56.09-11.89,41.49-10.63,58.41-23.94,58.41-45.95C367.72,108.63,351.84,95.47,313.13,85Z" />
        <path d="M121.65,82a27.14,27.14,0,0,0-9.44-7.51c-.49-.24-1-.46-1.49-.67l-3.59-1.48,3.16-2.25c8.12-5.81,12.24-13.23,12.24-22.06,0-7.36-2.82-14-8.38-19.89-6.75-7.12-15.82-10.73-27-10.73a41.57,41.57,0,0,0-17.71,3.69A30,30,0,0,0,57.1,31.3c-2.94,4.28-5.16,10.07-6.61,17.2l-.11.56,21.85,3.71.07-.58c.57-5,2.18-8.78,4.8-11.34A13.07,13.07,0,0,1,86.55,37a12,12,0,0,1,9,3.36c2.26,2.26,3.35,5.22,3.35,9a13.61,13.61,0,0,1-4.61,10.74c-3.07,2.69-7.62,4-13.54,3.83l-.49,0L78.11,79.62l-.54,4,.81-.22a38.87,38.87,0,0,1,10.17-1.66A14.08,14.08,0,0,1,99.26,86.5c3,3.23,4.41,7.5,4.41,13.06a28.15,28.15,0,0,1-.36,4.63,17.72,17.72,0,0,1-4.25,9.24,15.28,15.28,0,0,1-22,.85,16,16,0,0,1-3.49-5.07,25.28,25.28,0,0,1-1.94-7.32l-.07-.54-22.85,2.77.07.54a41,41,0,0,0,2.44,10.17A35.38,35.38,0,0,0,61.15,129c7.05,6.17,16.06,9.3,26.76,9.3,11.29,0,20.87-3.71,28.46-11a35.85,35.85,0,0,0,11.42-25.72c0-.33,0-.66,0-1A28.74,28.74,0,0,0,121.65,82Z" />
        <path d="M211.06,71.18c-.37-.43-.74-.85-1.13-1.27-6.77-7.23-15-10.89-24.52-10.89a26.37,26.37,0,0,0-16.28,5.33l-4,3,.56-5c1.05-9.39,2.89-15.85,5.47-19.2,3.16-4.1,7.06-6.19,11.6-6.19a11.65,11.65,0,0,1,8.34,3c2.13,2,3.48,5.26,4,9.59l.07.54,22.88-2.52-.1-.58c-1.62-9.58-5.43-17-11.34-22s-13.48-7.61-22.48-7.61c-12.8,0-23.36,4.85-31.38,14.43-7.14,8.53-11.13,22-11.9,40.13q-.14,3.26-.14,6.7a123.49,123.49,0,0,0,1.73,21.73c1.83,10.18,5.12,18.16,9.83,23.85a36.94,36.94,0,0,0,29.62,14c11.14,0,20.4-3.72,27.51-11.05,6.69-6.88,10.27-16,10.68-27.11,0-.71,0-1.42,0-2.15C220.12,87.22,217.07,78.23,211.06,71.18ZM193,113.84a12.64,12.64,0,0,1-10,4.69c-4.19,0-7.82-1.89-11.1-5.77-2.76-3.26-4.36-7.77-4.78-13.41Q167,97.84,167,96.2c0-6.44,1.46-11.36,4.34-14.63a13.86,13.86,0,0,1,21.32.23c2.88,3.43,4.35,9,4.35,16.4,0,.37,0,.72,0,1.07C196.88,106,195.53,110.87,193,113.84Z" />
        <path d="M297.43,29.8h0c-6.47-8.2-15.58-12.36-27.06-12.36s-20.57,4.1-27,12.2c-7.09,9-11,23.31-11.56,42.7-.05,1.73-.08,3.5-.08,5.32A150.57,150.57,0,0,0,233.32,101c1.66,10.54,4.65,18.46,9,23.93,7.06,8.87,16.49,13.37,28,13.37s20.56-4.11,27-12.21a45.34,45.34,0,0,0,7.32-14.45q3.65-11.4,4.21-27.73.11-2.91.1-6C309,55.63,305.09,39.45,297.43,29.8Zm-15.34,77.1,0,.13c-1.14,4.33-2.74,7.37-4.77,9a10.95,10.95,0,0,1-13.83,0c-2-1.62-3.7-5-5-10-.21-.8-.41-1.73-.59-2.75-1-5.44-1.47-14-1.47-25.46,0-1.13,0-2.21,0-3.28.12-12,.88-20.68,2.28-25.95,1.15-4.39,2.72-7.35,4.77-9a10.79,10.79,0,0,1,6.94-2.48,10.58,10.58,0,0,1,6.88,2.48c2,1.65,3.71,5,5.06,10s2.06,14.57,2.06,28.22v.77C284.33,91.8,283.57,101.3,282.09,106.9Z" />
        <path d="M238.19,175.25,200.94,138v22.54q-8.48.21-17.08.22c-42.86,0-84-4-115.89-11.3-30.78-7-43.81-15-49.06-19.51,3.87-3.32,12-8.51,28.23-13.82A102.37,102.37,0,0,1,41.22,89C12.19,98.86,0,111.27,0,129.94c0,22,16.92,35.32,58.41,45.95,33.57,8.61,78.12,13.35,125.45,13.35q8.61,0,17.08-.22v23.49Z" />
      </g>
    </g>
  </SvgIcon>
);

export default IconVirtualTour360;
