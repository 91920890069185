import React, { useMemo } from 'react';
import { AnimatedSwitch, AnimatedSwitchProps } from 'react-router-transition';

import { makeStyles } from '@material-ui/core';

import styles from './AnimatedSwitchFade.styles';

const useStyles = makeStyles(styles);

const AnimatedSwitchFade: React.FC<
  Omit<AnimatedSwitchProps, 'atEnter' | 'atLeave' | 'atActive' | 'className'>
> = (props) => {
  const classes = useStyles();
  const animations = useMemo(
    () => ({
      enter: { opacity: 0 },
      leave: { opacity: 0 },
      active: { opacity: 1 },
    }),
    []
  );
  return (
    <AnimatedSwitch
      {...props}
      atEnter={animations.enter}
      atLeave={animations.leave}
      atActive={animations.active}
      className={classes.switchWrapper}
    />
  );
};

export default AnimatedSwitchFade;
