import { Theme } from '@material-ui/core';
import { createStyles, fade } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: fade(theme.palette.common.black, 0.8),
      [theme.breakpoints.up('xs')]: {
        ...theme.typography.subtitle1,
      },
    },
  });

export default styles;
