import clsx from 'clsx';
import React, { forwardRef } from 'react';

import { Button, makeStyles } from '@material-ui/core';

import styles from './ButtonImageSlider.styles';

import type { ButtonImageSliderProps } from './ButtonImageSlider.types';

const useStyles = makeStyles(styles);

const ButtonImageSlider: React.ForwardRefRenderFunction<
  any,
  ButtonImageSliderProps
> = ({ imgProps, children, disableImageSlider, ...other }, ref) => {
  const classes = useStyles();
  return (
    <Button
      {...other}
      ref={ref}
      classes={{
        root: clsx(classes.buttonRoot, {
          [classes.disableImageSlider]: disableImageSlider,
        }),
        focusVisible: classes.buttonFocusVisible,
      }}
    >
      {children}
      <div className={classes.buttonImageWrapper}>
        <img
          className={classes.buttonImage}
          alt={imgProps?.alt}
          {...imgProps}
        />
      </div>
    </Button>
  );
};

export default forwardRef(ButtonImageSlider);
