import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      color: theme.palette.common.white,
      borderTopRightRadius: theme.shape.borderRadius * 6,
      borderBottomRightRadius: theme.shape.borderRadius * 6,
      boxShadow: theme.shadows[4],
    },
  });

export default styles;
