import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.common.white,
      textAlign: 'center',
    },
    root: {
      transition: theme.transitions.create('background-color'),
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    selected: {
      backgroundColor: theme.palette.primary.light,
    },
  });

export default styles;
