// Import assets for panonara view.
import './assets/js/pano2vr_player';

import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@material-ui/core';

import { BackdropLoader } from './components';
import { RootRoutes } from './routes';
import theme from './styles';

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <Suspense fallback={<BackdropLoader open />}>
        <RootRoutes />
      </Suspense>
    </Router>
  </ThemeProvider>
);

export default App;
